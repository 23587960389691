<template>
  <div id="app">
    <Nav />
    <main class="container">
      <router-view />
    </main>
    <Footer />
    <Modals />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Nav from './components/layout/Nav'
import Footer from './components/layout/Footer'
import Modals from './components/modals/Modals'

export default {
  components: {
    Nav,
    Footer,
    Modals
  },
  mounted() {
    this.checkAuth()
  },
  methods: {
    ...mapActions(['checkAuth'])
  }
}
</script>
