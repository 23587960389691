<template>
	<modal :adaptive="true" height="auto" :max-width="480" name="sign-in">
		<form class="text-center" @submit.prevent="submit">
			<h2 class="text-primary v--modal-heading">Sign in</h2>
			<input
				class="input border-0 text-primary py-3 px-4 mb-3 w-100"
				type="email"
				v-model="form.username"
				v-bind:class="error_msg ? 'invalid' : ''"
				placeholder="Email Address"
			/>
			<input
				class="input border-0 text-primary py-3 px-4 mb-3 w-100"
				type="password"
				v-model="form.password"
				placeholder="Password"
			/>
      <span v-if="error_msg" class="text-danger">{{error_msg}}</span>
			<button class="btn btn-primary w-100 mb-2" type="submit">
				<span class="mr-3">Sign In</span>	
				<half-circle-spinner
					v-if="loading"
					:animation-duration="2000"
					:size="20"
					color="#fff"
				/>
			</button>
			<a href="javascript:void(0)" class="btn btn-secondary mb-1" v-on:click="signUpInstead">Sign Up</a>
			<p class="text-primary m-auto v--modal-text">
				By signing up or logging in with Facebook or Email you accept our
				<a href="https://app.club-global.com/privacy-policy" class="font-weight-bold" target="_blank">privacy policy</a> and
				<a href="https://app.club-global.com/terms-and-conditions" class="font-weight-bold" target="_blank">terms and conditions</a>
			</p>
		</form>
	</modal>
</template>

<script>
	import {mapActions} from 'vuex'
	import { HalfCircleSpinner } from 'epic-spinners'

	export default {
		components: {
			HalfCircleSpinner,
		},
		data() {
			return {
				form: {
					username: null,
					password: null
				},
				error_msg: null,
				loading: false,
			}
		},
		methods: {
			...mapActions(['login']),
			submit() {
				this.loading = true;
				let _this = this;

				_this.login(this.form)
					.then(() => {
						this.loading = false;
						_this.$modal.hide('sign-in');
						_this.resetForm();
					})
					.catch((error) => {
						this.loading = false;

						if (error.response.status === 401) {
							_this.error_msg = 'Incorrect email or password.';
						} else {
							_this.error_msg = 'Failed to login. Please contact the administrator'
						}
					})
			},
			resetForm() {
				this.form = {
					username: null,
					password: null
				}
			},
			signUpInstead(){
				this.$modal.hide('sign-in');
				this.$modal.show('sign-up');
			}
		},
        watch: {
            form: {
                handler(){
                    this.error_msg = '';
                },
                deep: true
            }
        }
	}
</script>
