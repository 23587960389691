<template>
	<nav class="py-3 py-lg-4 fixed-top bg-primary">
		<div class="container d-flex justify-content-between align-items-center">
			<div class="d-flex align-items-center">
				<img src="../../assets/logo.svg" alt="Club Global logo"/>
				<img class="d-none d-sm-block ml-2" src="../../assets/club-global.svg" alt="Club Global"/>
			</div>
			<button
				v-if="!userIsLoggedIn"
				class="btn btn-secondary btn-sign-in w-100"
				@click="$modal.show('sign-in')"
			>Sign in
			</button>
			<button v-else class="btn btn-secondary btn-sign-in w-100" @click="logoutButton">Sign out</button>
		</div>
	</nav>
</template>

<script>
	import {mapState, mapActions} from 'vuex'

	export default {
		computed: {
			...mapState(['userIsLoggedIn'])
		},

		methods: {
			...mapActions(['logout']),
			logoutButton() {
              this.logout();
              window.location.reload();
			}
		}
	}
</script>