<template>
  <footer class="container mt-5">
    <hr class="light-purple" />
    <div class="text-center pt-4 pb-5">
      <img class="d-block mx-auto mb-3" src="../../assets/logo.svg" alt="Club Global logo" />
      <img class="d-block mx-auto mb-4" src="../../assets/club-global.svg" alt="Club Global" />
      <div>© Copyright {{ (new Date).getFullYear() }}</div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>