import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter);

const routes = [
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/Payment.vue')
  },
  {
    path: '/payment_completed',
    name: 'payment_completed',
    component: () => import('../views/PaymentCompleted.vue')
  },
  {
    path: '/venues/:venue',
    name: 'venue',
    component: () => import('../views/Venue.vue')
  },
  {
    path: '/venues/:venue/events/:event',
    name: 'venue-event',
    component: () => import('../views/Event.vue')
  },
  {
    path: '*',
    name: '404',
    alias: '/404',
    component: () => import('../views/404.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(item => item.meta.requiresAuth)) {
    store.dispatch('checkAuth').then(loggedIn => {
      if (loggedIn) {
        next()
      } else {
        next(store.state.ROUTER_REDIRECT_IF_NO_AUTH)
      }
    });
    return
  }
  next()
});

export default router
