<template>
	<modal :adaptive="true" height="auto" :max-width="480" name="enter-phone-number" @closed="emitEvent">
		<form class="text-center" @submit.prevent="submit">
			<h2 class="text-primary v--modal-heading">Enter Phone Number</h2>
      		<p class="text-primary">Phone Number is required to comply with track and trace guidelines and communication on event updates.</p>
      		<span v-if="error_msg" class="text-danger">{{error_msg}}</span>
			<input
				class="input border-0 text-primary py-3 px-4 mb-3 w-100"
				type="tel"
				:value="phoneNumber"
        		@input="setPhoneNumber"
				placeholder="Phone Number"
			/>

			<div class="w-100 text-left mb-3">
				<div class="custom-control custom-checkbox">
					<input 
						type="checkbox" 
						class="custom-control-input" 
						id="permissions-checkbox"
						:value="marketingPermissions"
						@input="setMarketingPermissions"
					>
					<label class="custom-control-label text-primary" for="permissions-checkbox">Also sign up to receive the latest news and offers from Club Global.</label>
				</div>
			</div>

			<button class="btn btn-primary w-100 mb-2" type="submit">
				<span class="mr-3">Continue</span>	
			</button>
		</form>
	</modal>
</template>

<script>
	import {mapState, mapMutations} from 'vuex'
	import { HalfCircleSpinner } from 'epic-spinners'

	export default {
		components: {
			HalfCircleSpinner,
		},

		data(){
			return {
				error_msg: null
			}
		},
		
		methods: {
			...mapMutations(['setPhoneNumber', 'setMarketingPermissions']),

			submit() {
				if(this.phoneNumber == ''){
					this.error_msg = 'Please Enter Phone Number';
					return;
				}

				if(!(/([0-9]{11})/.test(this.phoneNumber))){
					this.error_msg = 'Phone Number Must Be 11 Characters Long With No Spaces';
					return;
				}
				
				this.$modal.hide('enter-phone-number');
			},

			emitEvent(){
				this.$root.$emit('phone-modal-closed');
			}
		},
		
		computed: {
			...mapState(['userIsLoggedIn', 'phoneNumber', 'marketingPermissions']),
		}
	}
</script>
