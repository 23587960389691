<template>
  <modal :adaptive="true" height="auto" :max-width="480" name="sign-in-required">
    <div class="text-center pb-sm-4">
      <img class="d-block mx-auto" src="../../assets/logo.svg" alt="Club Global logo" />
      <img class="d-block mx-auto mt-3" src="../../assets/club-global-dark.svg" alt="Club Global" />
      <h2 class="text-primary v--modal-heading mt-4 mb-0 mt-sm-5">Sign In Required</h2>
      <p class="text-primary mx-auto v--modal-text mt-2 mb-0 v--modal-sign-in-text">
        You must have an account to purchase tickets and book tables at club venues.
      </p>
      <div class="mt-4 mt-sm-5 d-sm-flex justify-content-center">
        <button class="btn btn-primary w-100 mb-2 v--modal-btn mr-sm-2" @click="signIn">Sign In</button>
        <button class="btn btn-secondary w-100 v--modal-btn" @click="signUp">Sign up</button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  methods: {
    signIn() {
      this.$modal.hide('sign-in-required')
      this.$modal.show('sign-in')
    },
    signUp() {
      this.$modal.hide('sign-in-required')
      this.$modal.show('sign-up')
    }
  }
}
</script>
