import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal'
import VueAnalytics from 'vue-analytics'
import './styles/app.scss'

Vue.use(VueAnalytics, {
  id: 'UA-50045817-20',
  router
})

Vue.use(VModal)

// Vue.config.productionTip = false;
// Vue.config.devtools = true;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
