<template>
	<modal :adaptive="true" height="auto" :max-width="480" name="sign-up">
		<form class="text-center" @submit.prevent="submit">
			<h2 class="text-primary v--modal-heading">Create Account</h2>
			<div class="d-flex justify-content-between">
				<div class="pr-3">
					<input
						class="input input--inline text-primary w-100 px-4 py-3 mr-2"
						v-bind:class="errors.first_name ? 'border-danger': 'border-0'"
						type="text"
						v-model="form.first_name"
						placeholder="First name"
					/>
					<span class="text-danger" v-if="errors.first_name">{{errors.first_name.join('\n')}}</span>
				</div>
				<div class="">
					<input
						class="input input--inline text-primary w-100 px-4 py-3"
						type="text"
						v-model="form.last_name"
						v-bind:class="errors.last_name ? 'border-danger': 'border-0'"
						placeholder="Last name"
					/>
					<span class="text-danger" v-if="errors.last_name">{{errors.last_name.join('\n')}}</span>
				</div>
			</div>
			<div class="">
				<input
					class="input w-100 text-primary py-3 px-4 mt-3"
					type="email"
					v-model="form.email"
					v-bind:class="errors.email ? 'border-danger': 'border-0'"
					placeholder="Email"
				/>
				<span class="text-danger" v-if="errors.email">{{errors.email.join('\n')}}</span>
			</div>
			<div class="">
				<input
					class="input w-100 text-primary py-3 px-4 mt-3"
					type="password"
					v-model="form.password"
					v-bind:class="errors.password ? 'border-danger': 'border-0'"
					placeholder="Password"
				/>
				<span class="text-danger" v-if="errors.password">{{errors.password.join('\n')}}</span>
			</div>
			<button class="btn btn-primary w-100 mt-3" type="submit">
				<span class="mr-3">Sign Up</span>	
				<half-circle-spinner
					v-if="loading"
					:animation-duration="2000"
					:size="20"
					color="#fff"
				/>
			</button>
			<p class="text-primary mx-auto v--modal-text mt-3 mb-0">
				By signing up or logging in with Facebook or Email you accept our
				privacy policy and terms and conditions
			</p>
		</form>
	</modal>
</template>

<script>
	import {mapActions} from 'vuex'
	import { HalfCircleSpinner } from 'epic-spinners'
	
	export default {
		components: {
			HalfCircleSpinner,
		},

		computed: {
			name() {
				return this.form.first_name + ' ' + this.form.last_name
			}
		},

		data() {
			return {
				form: {
					first_name: null,
					last_name: null,
					email: null,
					password: null
				},

				errors: {},

				loading: false
			}
		},

		methods: {
			...mapActions(['register']),
			submit() {
				this.loading = true;

				this.register({
					name: this.name,
					email: this.form.email,
					password: this.form.password
				})
					.then(() => {
						this.loading = false;
						this.$modal.hide('sign-up')
					})
					.catch((e) => {
						this.loading = false;
						this.errors = e.response.data.errors
					});
			}
		},

		watch: {
			'form.first_name'() {
				delete this.errors.first_name;
			},
			'form.last_name'() {
				delete this.errors.last_name;
			},
			'form.email'() {
				delete this.errors.email;
			},
			'form.password'() {
				delete this.errors.password;
			}
		}
	}
</script>
