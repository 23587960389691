<template>
  <div>
    <sign-in />
    <sign-in-required />
    <sign-up />
    <phone-number />
  </div>
</template>

<script>
import SignIn from './SignIn'
import SignInRequired from './SignInRequired'
import SignUp from './SignUp'
import PhoneNumber from './PhoneNumber'

export default {
  components: {
    SignIn,
    SignInRequired,
    SignUp,
    PhoneNumber
  }
}
</script>