import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router/index'
import {format, isPast, endOfDay, addMonths} from 'date-fns'

window.axios = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	headers: {'Device-Type': 'frontend', 'Device-Version': 1}
})

if (localStorage.getItem('token')) {
	window.axios.defaults.headers.common[
		'Authorization'
		] = `Bearer ${localStorage.getItem('token')}`
}

function getApiResponse(resp) {
	return resp.data.success.data
}

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		ROUTER_REDIRECT_IF_NO_AUTH: 'home',
		venues: {},
		cities: {},
		bookingItems: null,
		currentVenue: null,
		currentEvent: null,
		eventAvailability: {},
		token: localStorage.getItem('token') || null,
		client_secret: process.env.VUE_APP_CLIENT_SECRET,
		user: {},
		stripe_key: process.env.VUE_APP_STRIPE_KEY,
		userIsLoggedIn: false,
		selectedDate: new Date(),
		calendarDays: {},
		phoneNumber: '',
		marketingPermissions: false,
		tracking_id: null
	},

	mutations: {
		saveTrackingId(state, tracking_id) {
			state.tracking_id = tracking_id;
		},
		addVenue(state, venue) {
			Vue.set(state.venues, venue.slug, venue)
		},
		addCity(state, city) {
			Vue.set(state.cities, city.id, city)
		},
		setCurrentVenue(state, venueId) {
			state.currentVenue = venueId
		},
		setCurrentEvent(state, eventId) {
			state.currentEvent = eventId
		},
		login(state, {token, user}) {
			state.userIsLoggedIn = true
			state.token = token
			state.user = user
		},
		logout(state) {
			state.userIsLoggedIn = false
			state.token = null
			state.user = {}
			state.bookingItems = null;
		},
		setSelectedDate(state, date) {
			state.selectedDate = date
		},
		addCalendarDays(state, {year, month, days}) {
			if (state.calendarDays[year] == undefined) {
				Vue.set(state.calendarDays, year, {})
			}
			Vue.set(state.calendarDays[year], month, days)
		},
		addAvailabilityForDay(state, {day, date}) {
			const calendarDays = state.calendarDays

			if (
				!calendarDays[date.getFullYear()] ||
				!calendarDays[date.getFullYear()][date.getMonth()]
			) {
				return false
			}

			state.calendarDays[date.getFullYear()][date.getMonth()][
				format(date, 'dd')
				] = {
				...calendarDays[date.getFullYear()][date.getMonth()][
					format(date, 'dd')
					],
				availability: day
			}
		},
		setAvailabilityForCurrentEvent(state, availability) {
			state.eventAvailability = availability
		},
		setOpeningHoursForCurrentVenue(state, openingHours) {
			Vue.set(state.venues[state.currentVenue], 'opening_hours', openingHours)
		},

		setPhoneNumber(state, event){
			state.phoneNumber = event.target.value;
		},

		setMarketingPermissions(state, event){
			state.marketingPermissions = event.target.checked;
		},

		setBookingItems(state, bookingItems){
			state.bookingItems = bookingItems;
		}
	},

	actions: {
		trackClick({commit, state}, rep_param) {
			console.log(rep_param);
			return new Promise(resolve => {
				if(rep_param == undefined){
					resolve();
				}

				window.axios.post(`/api/rep/track-click/${state.currentVenue}/${rep_param}`).then(response => {
								commit('saveTrackingId', getApiResponse(response).tracking_id)

								resolve();
							});
            })
		},
		getVenue({commit, state}) {
			return new Promise(resolve => {
				const venue = state.venues[state.currentVenue]

				if (venue) return resolve(venue)

				window.axios.get(`/api/venue/${state.currentVenue}`).then(resp => {
					commit('addVenue', getApiResponse(resp))
					resolve()
				})
			})
		},
		getCity({commit, state, getters}) {
			return new Promise(resolve => {
				const venue = state.venues[state.currentVenue]

				if (venue && state.cities[venue.city_id]) {
					return resolve(state.cities[venue.city_id])
				}

				window.axios.get(`/api/city/${getters.cityId}`).then(resp => {
					commit('addCity', {id: getters.cityId, ...getApiResponse(resp)})
					resolve()
				})
			})
		},
		getOpeningHours({state, commit}) {
			return window.axios
				.get(`/api/venue/${state.currentVenue}/hours`)
				.then(resp => {
					const openingHours = getApiResponse(resp)
					Object.keys(openingHours).forEach(key => {
						if (openingHours[key].opens == 'closed') return

						const opensDate = new Date()
						const closesDate = new Date()

						const [opensHours, opensMinutes] = openingHours[key].opens.split(
							':'
						)

						const [closeHours, closeMinutes] = openingHours[key].closes.split(
							':'
						)

						opensDate.setHours(opensHours, opensMinutes)
						closesDate.setHours(closeHours, closeMinutes)

						openingHours[key].opens = format(opensDate, 'ha')
						openingHours[key].closes = format(closesDate, 'ha')
					})

					commit('setOpeningHoursForCurrentVenue', openingHours)
				})
		},
		login({state, commit}, user) {
			return window.axios
				.post('/oauth/token', {
					grant_type: 'password',
					client_id: 1,
					client_secret: state.client_secret,
					username: user.username,
					password: user.password,
					scope: '*'
				})
				.then(resp => {
					const token = resp.data.access_token

					localStorage.setItem('token', token)
					window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

					commit('login', {token, user})
				})
				.catch(function (error) {
					if (error.response && error.response.status == 401) {
						localStorage.removeItem('token')
					}
					throw error;
				})
		},
		logout({commit}) {
			return new Promise(resolve => {
				commit('logout')
				localStorage.removeItem('token')
				delete window.axios.defaults.headers.common['Authorization']
				resolve()
			})
		},
		getUserLoggedIn({state, commit}) {
			return new Promise(resolve => {
				if (state.userIsLoggedIn) {
					resolve(state.userIsLoggedIn)
				}
				window.axios
					.get('/api/users')
					.then(resp => {
						commit('login', {
							user: getApiResponse(resp),
							token: state.token
						})

						resolve(state.userIsLoggedIn)
					})
					.catch(() => {
						resolve(false)
					})
			})
		},
		checkAuth({state, dispatch}) {
			return new Promise(resolve => {
				if (state.token != null) {
					dispatch('getUserLoggedIn').then(loggedIn => {
						resolve(loggedIn)
					})
				} else {
					resolve(false)
				}
	 		})
		},
		getCalendarDays({state, commit}, date) {
			return new Promise(resolve => {
				if (
					state.calendarDays[date.getFullYear()] != undefined &&
					state.calendarDays[date.getFullYear()][date.getMonth()] != undefined
				) {
					return resolve(
						state.calendarDays[date.getFullYear()][date.getMonth()]
					)
				}

				window.axios
					.get(
						`/api/venue-calendar/${state.currentVenue}/${date.getMonth() +
						1}/${date.getFullYear()}`
					)
					.then(resp => {
						commit('addCalendarDays', {
							days: getApiResponse(resp),
							year: date.getFullYear(),
							month: date.getMonth()
						})
						resolve(state.calendarDays[date.getFullYear()][date.getMonth()])
					})
			})
		},
		nextMonth({dispatch}, date) {
			dispatch('selectDate', date)
			dispatch('getCalendarDays', addMonths(date, 1))
		},
		selectDate({commit, dispatch, getters}, date) {
			commit('setSelectedDate', date)

			if (getters.dateHasNoTicketsOrTables(date)) return

			dispatch('getAvailabilityForSelectedDate', date)
		},
		getAvailabilityForDate({state}, date) {
			return new Promise((resolve, reject) => {
				if (isPast(endOfDay(new Date(format(date, 'yyyy-MM-dd')))))
					return reject()

				window.axios
					.get('/api/bookings/get_items_available', {
						params: {
							venue_id: state.currentVenue,
							date: format(date, 'dd-MM-yyyy')
						}
					})
					.then(resp => resolve(resp))
			})
		},
		getAvailabilityForSelectedDate({commit, state, getters, dispatch}) {
			return (
				getters.selectedDateAvailability ||
				dispatch('getAvailabilityForDate', state.selectedDate).then(resp => {
					commit('addAvailabilityForDay', {
						date: state.selectedDate,
						day: getApiResponse(resp)
					})
				})
			)
		},
		getAvailabilityForEvent({commit, dispatch, getters}) {
			dispatch(
				'getAvailabilityForDate',
				new Date(getters.event.date)
			).then(resp => {
				commit('setAvailabilityForCurrentEvent', getApiResponse(resp))
				commit('addAvailabilityForDay', 
					{
						date: new Date(getters.event.date),
						day: getApiResponse(resp)
					}
				)
				commit('setSelectedDate', new Date(getters.event.date));
			})
		},
		createUser(context, {name, email, password}) {
			return window.axios.post('/api/users', {
				grant_type: null,
				name,
				email,
				password
			})
		},
		register({commit, dispatch}, user) {
			return dispatch('createUser', user).then(resp => {
				const token = resp.data.success.data.access_token

				localStorage.setItem('token', token)
				window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

				commit('login', {token, user})
			})
		},

		createBooking({state}, bookingObject){
			if(state.tracking_id !== null){
				bookingObject.rep_tracking_id = state.tracking_id;
			}

			return window.axios.post('api/booking/create', bookingObject);
		},

		confirmBooking({state}, booking){
			return window.axios.post('api/booking/' + booking.id + '/confirm_payment');
		}
	},

	getters: {
		venue(state) {
			return state.venues[state.currentVenue] || null
		},
		city(state, {cityId}) {
			return state.cities[cityId] || {}
		},
		cityId(state) {
			return state.venues[state.currentVenue] != undefined
				? state.venues[state.currentVenue].city_id
				: null
		},
		event(state, {venue}) {
			return venue != null
				? venue.venue_events.filter(event => event.id == state.currentEvent)[0]
				: null
		},
		venueTicketPriceTotal: (state, { selectedDateAvailability }) => tickets => {
			// const ticketPrice = selectedDateAvailability
			// 	? selectedDateAvailability.ticket_price
			// 	: state.eventAvailability
			// 		? state.eventAvailability.ticket_price
			// 		: null

			const ticketPrice = state.eventAvailability && state.eventAvailability.ticket_price
				? state.eventAvailability.ticket_price
				: selectedDateAvailability
					? selectedDateAvailability.ticket_price
					: null

			const bookingFee = selectedDateAvailability.ticket_booking_fee

			return new Intl.NumberFormat('en-GB', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			}).format(((ticketPrice + bookingFee) / 100) * (tickets || 0))
		},
		venueOpeningHours(state, {venue, selectedDayName}) {
			if (!venue || !venue.opening_hours) return null

			const openingHours = venue.opening_hours[selectedDayName]
			if (!openingHours){
				return 'closed';
			}

			return openingHours.opens == 'closed'
				? openingHours.opens
				: openingHours.opens + '-' + openingHours.closes
		},
		selectedDateHasTickets(state, {selectedDateAvailability}) {
			const day = selectedDateAvailability

			return (
				day != null && day.num_tickets_available > 0 && day.ticket_price > 0
			)
		},
		selectedDateHasTables(state, {selectedDateAvailability}) {
			const day = selectedDateAvailability

			return (
				day != null &&
				day.tables != null &&
				day.tables.filter(table => table.price > 0).length
			)
		},
		venueTicketBookingFee(state, {selectedDateAvailability}) {
			return (selectedDateAvailability.ticket_booking_fee / 100).toFixed(2)
		},
		ticketsAvailable(state, {selectedDateAvailability}){
			return state.eventAvailability.num_tickets_available || selectedDateAvailability.num_tickets_available;
		},
		selectedDayName(state) {
			return state.selectedDate.toLocaleDateString('en-gb', {weekday: 'long'})
		},
		selectedYear(state) {
			return state.selectedDate.getFullYear()
		},
		selectedMonth(state) {
			return state.selectedDate.getMonth()
		},
		calendarDays: state => date => {
			return state.calendarDays[date.getFullYear()] != undefined
				? state.calendarDays[date.getFullYear()][date.getMonth()]
				: []
		},
		calendarDay: state => date => {
			return state.calendarDays[date.getFullYear()]
				? state.calendarDays[date.getFullYear()][date.getMonth()]
					? state.calendarDays[date.getFullYear()][date.getMonth()][
						format(date, 'dd')
						]
					: null
				: null
		},
		selectedDateAvailability(state, {calendarDay}) {
			return calendarDay(state.selectedDate) != null
				? calendarDay(state.selectedDate).availability
				: state.eventAvailability || null
		},
		highlightedCalendarDays(state, {calendarDays}) {
			if (calendarDays(state.selectedDate) == undefined || calendarDays(state.selectedDate) == []) return {};

			let data = calendarDays(state.selectedDate);

			return {
				daysOfMonth: Object.keys(data)
					.filter(key => data[key].tables || data[key].tickets)
					.map(val => parseInt(val))
			}
		},
		dateHasNoTicketsOrTables: (state, {calendarDay}) => date => {
			const day = calendarDay(date)

			return day != null && !day.tickets && !day.tables
		},
		currentEventHasTickets(state) {
			return (
				state.eventAvailability != null &&
				state.eventAvailability.num_tickets_available > 0 &&
				state.eventAvailability.ticket_price > 0
			)
		},
		currentEventHasTables(state) {
			return (
				state.eventAvailability != null &&
				state.eventAvailability.tables != null &&
				state.eventAvailability.tables.filter(table => table.price > 0).length
			)
		},
		bookingItems(state){
			return state.bookingItems;
		},
		getStripeKey(state){
			return state.stripe_key
		}
	},

	modules: {}
})

window.axios.interceptors.response.use(
	function (response) {
		return response
	},
	function (error) {
		if (error.response){
			if (error.response.status === 403){
				store.dispatch('logout');
			}
			else if (error.response.status === 404){
				router.push('/');
			}
		}
		else if (error.status === 403) {
			store.dispatch('logout')
		}
		else {
			//We have an actual error. Display an error page?
		}

		return Promise.reject(error)
	}
)

export default store
